/*External dependencies */
import { gql } from '@apollo/client';

export const createItemMutation = gql`
  mutation CreateItem($input: CreateItemInput!) {
    createItem(input: $input) {
      id
      name_en
      name_ru
      name_ky
      status
      parentId
      account {
        id
        name
      }
      currency
      requestId
      popularityScore
      merchantCategoryCode
      registrationDate
      qrCode {
        image
        url
      }
    }
  }
`;