// Local dependencies
import { Account } from '../accounts/listAccounts/types';

export interface ActionHistory {
  operator?: User;
  comment?: string;
  createdDate: number;
  documentIdentificationStatus?: UserDocumentStatus;
  faceIdentificationStatus?: UserDocumentStatus;
  blackListIdentificationStatus?: BlackListIdentificationStatus;
}

export interface UserDocument {
  id: string;
  actionHistory?: [ActionHistory];
  documentIdentificationStatus?: UserDocumentStatus;
  faceIdentificationStatus?: UserDocumentStatus;
  blacklistIdentificationStatus?: BlackListIdentificationStatus;
  personalData?: PersonalData;
  createdDate?: number;
  updatedDate?: number;
  comment?: string;
}

export enum UserPersonalProfileType {
  CHECKBOX = 'CHECKBOX',
  DATE = 'DATE',
  TEXT = 'TEXT',
}

export interface User {
  accounts?: [Account];
  birthDate?: string;
  disabledDate?: number;
  enabledDate?: number;
  firstName?: string;
  gender?: string;
  groupName: UserGroup;
  id: string;
  identificationDocument?: UserDocument;
  identificationStatus: UserIdentificationStatus;
  joinDate?: string;
  lastName?: string;
  patronymic?: string;
  phone: number;
  settings?: UserSettings;
  status: UserStatus;
  personalProfile?: UserPersonalProfile;
}

export interface UserPersonalProfile {
  date?: string;
  fieldName?: String;
  type: UserPersonalProfileType;
  label_en?: String;
  label_ky?: String;
  label_ru?: String;
  options?: [UserPersonalProfile];
  checked?: Boolean;
  value?: String;
}

export interface PersonalData {
  pin: string;
  documentNumber: string;
  front: string;
  back: string;
  selfie: string;
}

export enum BlackListIdentificationStatus {
  BLACK_LIST_IDENTITY_PASSED = 'BLACK_LIST_IDENTITY_PASSED',
  BLACK_LIST_IDENTITY_FAILED = 'BLACK_LIST_IDENTITY_FAILED',
}

export enum UserStatus {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
}

export enum UserIdentificationStatus {
  IDENTIFIED = 'IDENTIFIED',
  PENDING_REVIEW = 'PENDING_REVIEW',
  UNIDENTIFIED = 'UNIDENTIFIED',
  FAILED = 'FAILED',
  ON_SITE = 'ON_SITE',
  OFFLINE_IDENTIFIED = 'OFFLINE_IDENTIFIED',
  BLACK_LIST_IDENTITY_PASSED = 'BLACK_LIST_IDENTITY_PASSED',
  TUNDUK_IDENTIFIED = 'TUNDUK_IDENTIFIED',
}

export enum UserGroup {
  AVERSPAY_USER = 'AVERSPAY_USER',
  ACCOUNTANT = 'ACCOUNTANT',
  ADMIN = 'ADMIN',
  DEALER = 'DEALER',
  MANAGER = 'MANAGER',
  OPERATOR = 'OPERATOR',
  USER = 'USER',
  COMPLIANCE_MANAGER = 'COMPLIANCE_MANAGER',
}

export enum UserDocumentStatus {
  FAILED = 'FAILED',
  IDENTIFIED = 'IDENTIFIED',
  IN_REVIEW = 'IN_REVIEW',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  PENDING_REVIEW = 'PENDING_REVIEW',
  REJECTED = 'REJECTED',
  UNIDENTIFIED = 'UNIDENTIFIED',
  TUNDUK_IDENTIFIED = 'TUNDUK_IDENTIFIED',
  BLACK_LISTED = 'BLACK_LISTED',
}

export const userGroupRoles = [
  UserGroup.ACCOUNTANT,
  UserGroup.ADMIN,
  UserGroup.DEALER,
  UserGroup.MANAGER,
  UserGroup.OPERATOR,
  UserGroup.USER,
  UserGroup.COMPLIANCE_MANAGER,
];

export const userIdentificationStatusList = [
  UserIdentificationStatus.IDENTIFIED,
  UserIdentificationStatus.PENDING_REVIEW,
  UserIdentificationStatus.UNIDENTIFIED,
];

export enum FilterType {
  ALL_ROLES = 'ALL_ROLES',
  ALL_STATUSES = 'ALL_STATUSES',
  ALL_IDENTIFICATION_STATUSES = 'ALL_IDENTIFICATION_STATUSES',
}

export const userIdentificationStatusFilter = [
  FilterType.ALL_IDENTIFICATION_STATUSES,
  UserIdentificationStatus.IDENTIFIED,
  UserIdentificationStatus.UNIDENTIFIED,
  UserIdentificationStatus.ON_SITE,
  UserIdentificationStatus.OFFLINE_IDENTIFIED,
  UserIdentificationStatus.FAILED,
];

export const userRolesForFilter = [
  FilterType.ALL_ROLES,
  UserGroup.ACCOUNTANT,
  UserGroup.ADMIN,
  UserGroup.DEALER,
  UserGroup.MANAGER,
  UserGroup.OPERATOR,
  UserGroup.USER,
  UserGroup.COMPLIANCE_MANAGER,
];

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export interface UserSettings {
  lastNotificationDate: number;
}

export interface ListUsersInput {
  from?: number;
  locale?: string;
  query?: string;
  size?: number;
  filter?: {
    status?: UserIdentificationStatus;
  };
}

export enum UserRejectMessage {
  OTHER = 'OTHER',
  BLURRED_PASSPORT_PHOTO = 'BLURRED_PASSPORT_PHOTO',
  BLURRED_FACE_PHOTO = 'BLURRED_FACE_PHOTO',
  PHOTO_DOES_NOT_MATCH = 'photoDoesNotMatch',
  // PASSPORT_DETAILS_ARE_INCORRECT = 'PASSPORT_DETAILS_ARE_INCORRECT',
}
