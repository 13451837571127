/* External dependencies */
import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

/* Local dependencies */
import { FeesTypes } from '../services/types';
import { ServiceFees } from './types';

export const UPDATE_SERVICE_FEES_PATH = 'update-service-fees';
export const UPDATE_SERVICE_FEES_LOCAL_STORAGE_KEY = 'selectedServices';

export function getServiceFeesPageFields() {
  return [
    {
      field: 'entityType',
      title: <Trans>feeType</Trans>,
    },
    {
      field: 'feeID',
      title: <Trans>ID</Trans>,
    },
    {
      field: 'name',
      title: <Trans>name</Trans>,
    },
    {
      field: 'serviceID',
      title: <Trans>service</Trans>,
    },

    {
      field: 'upperFee',
      title: <Trans>UpperFee</Trans>,
    },
    {
      field: 'lowerFee',
      title: <Trans>LowerFee</Trans>,
    },
  ];
}

export function isNill(value) {
  return value === null || value === undefined;
}

export function getId({ id, serviceId }: { id: string; serviceId: string }) {
  return id + serviceId;
}

export function isNotDefault(entity) {
  return entity && entity !== 'default';
}

// this function gets the selected type
export function getServiceType(service: ServiceFees) {
  if (!isNill(service?.flatFee)) {
    return FeesTypes.FLAT_FEE;
  } else if (!isNill(service?.flatFeePercent)) {
    return FeesTypes.FLAT_FEE_PERCENT;
  } else if (!isNill(service?.dynamicFeeSteps)) {
    return FeesTypes.DYNAMIC_FEE_STEPS;
  }
}
