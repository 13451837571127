/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { Account } from '../../../../components/accounts/listAccounts/types';
import { UpdateAccountAction, UpdateAccountActionTypes } from './action';

export interface UpdateAccountState {
  error?: Error;
  loading?: boolean;
  account?: Account;
  isConfirmationPopupAccount?: boolean;
  isSuccesModal?: boolean;
}

export const initialUpdateAccountState: UpdateAccountState = {
  isConfirmationPopupAccount: false,
  loading: false,
  isSuccesModal: false,
};

export default function updateAccountReducer(state = initialUpdateAccountState, action: UpdateAccountAction) {
  switch (action.type) {
    case UpdateAccountActionTypes.UPDATE_ACCOUNT_REQUEST:
      return update(state, {
        loading: { $set: true },
      });

    case UpdateAccountActionTypes.UPDATE_ACCOUNT_SUCCESS:
      return update(state, {
        loading: { $set: false },
        account: { $set: action.account },
        isConfirmationPopupAccount: { $set: false },
        isSuccesModal: { $set: true },
      });

    case UpdateAccountActionTypes.UPDATE_ACCOUNT_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case UpdateAccountActionTypes.RESET_UPDATE_ACCOUNT:
      return update(state, {
        account: { $set: null },
        loading: { $set: initialUpdateAccountState.loading },
        isConfirmationPopupAccount: { $set: initialUpdateAccountState.isConfirmationPopupAccount },
        isSuccesModal: { $set: initialUpdateAccountState.isSuccesModal },
      });

    case UpdateAccountActionTypes.RESET_UPDATE_ACCOUNT_ERROR:
      return update(state, {
        error: { $set: null },
      });

    case UpdateAccountActionTypes.SHOW_CONFIRMATION_ACCOUNT_POPUP:
      return update(state, {
        isConfirmationPopupAccount: { $set: true },
      });

    case UpdateAccountActionTypes.HIDE_CONFIRMATION_ACCOUNT_POPUP:
      return update(state, {
        isConfirmationPopupAccount: { $set: false },
      });

    case UpdateAccountActionTypes.CLOSE_SUCCESS_POPUP:
      return update(state, {
        isSuccesModal: { $set: false },
      });

    default:
      return state;
  }
}
