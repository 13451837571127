/* Local dependencies */
import { RequiredServiceField } from '../../../../components/item/types';
import { Currency, ServiceFees } from '../../../../components/services/types';
import { Item } from '../../../../components/ui/selectedItems/types';

export enum CreateItemActionTypes {
  CREATE_ITEM_REQUEST = 'CREATE_ITEM_REQUEST',
  CREATE_ITEM_SUCCESS = 'CREATE_ITEM_SUCCESS',
  CREATE_ITEM_ERROR = 'CREATE_ITEM_ERROR',
  RESET_CREATE_ITEM_ERROR = 'RESET_CREATE_ITEM_ERROR',
  RESET_CREATE_ITEM = 'RESET_CREATE_ITEM',
}

export interface CreateItemInput {
  account: {
    id: string;
  };
  callbackUrl?: string;
  country?: string;
  currency?: Currency;
  endDate?: number;
  fees?: ServiceFees;
  fixedAmount?: number;
  maxAmount?: number;
  maxAmountPerUser?: number;
  maxAvailableQuantity?: number;
  maxQuantityPerUser?: number;
  mcc?: string;
  merchantCategoryCode?: string;
  minAmount?: number;
  name_en: string;
  parentId?: string;
  popularityScore?: number;
  requestId: string;
  requiredFields?: RequiredServiceField;
  startDate?: number;
  status: string;
  supportsDecimals?: boolean;
}

export interface ResetCreateItemError {
  type: CreateItemActionTypes.RESET_CREATE_ITEM_ERROR;
}

export interface ResetCreateItem {
  type: CreateItemActionTypes.RESET_CREATE_ITEM;
}

export interface CreateItem {
  type: CreateItemActionTypes.CREATE_ITEM_REQUEST;
  payload: CreateItemInput;
}

export interface CreateItemSuccess {
  type: CreateItemActionTypes.CREATE_ITEM_SUCCESS;
  payload: Item;
}

export interface CreateItemError {
  type: CreateItemActionTypes.CREATE_ITEM_ERROR;
  error: Error;
}

export type CreateItemActions =
  | CreateItem
  | CreateItemSuccess
  | CreateItemError
  | ResetCreateItemError
  | ResetCreateItem;

export function createItem(payload: CreateItemInput): CreateItem {
  return {
    type: CreateItemActionTypes.CREATE_ITEM_REQUEST,
    payload,
  };
}

export function createItemSuccess(payload: Item): CreateItemSuccess {
  return {
    type: CreateItemActionTypes.CREATE_ITEM_SUCCESS,
    payload,
  };
}

export function createItemError(error: Error): CreateItemError {
  return {
    type: CreateItemActionTypes.CREATE_ITEM_ERROR,
    error,
  };
}

export function resetCreateItemError(): ResetCreateItemError {
  return {
    type: CreateItemActionTypes.RESET_CREATE_ITEM_ERROR,
  };
}

export function resetCreateItem(): ResetCreateItem {
  return {
    type: CreateItemActionTypes.RESET_CREATE_ITEM,
  };
}
