// External dependencies
import update from 'immutability-helper';

/* Local dependencies */
import { Item } from '../../../../components/item/types';
import { CreateItemActions, CreateItemActionTypes } from './action';

interface CreateItemState {
  loading: boolean;
  error: Error;
  item?: Item | unknown;
}

const initialState: CreateItemState = {
  loading: false,
  error: null,
};

export default function createItemReducer(
  state: CreateItemState = initialState,
  action: CreateItemActions,
): CreateItemState {
  switch (action.type) {
    case CreateItemActionTypes.CREATE_ITEM_REQUEST:
      return update(state, {
        loading: { $set: true },
      });

    case CreateItemActionTypes.CREATE_ITEM_SUCCESS:
      return update(state, {
        loading: { $set: false },
        item: { $set: action.payload },
      });

    case CreateItemActionTypes.CREATE_ITEM_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case CreateItemActionTypes.RESET_CREATE_ITEM_ERROR:
      return update(state, {
        error: { $set: null },
      });

    case CreateItemActionTypes.RESET_CREATE_ITEM:
      return update(state, {
        item: { $set: null },
        loading: { $set: false },
        error: { $set: null },
      });

    default:
      return state;
  }
}
